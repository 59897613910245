import React from "react"
import Layout from "../components/layout"
import { HomeHeader, BannerCenter } from "../utils"
import contourMap from "../images/contour-map.svg"

const Success = props => (
  <Layout>
    <section id="success">
      <HomeHeader img={contourMap}>
        <BannerCenter
          title="Message Received"
          subtitle="Thanks for your interest."
        >
        </BannerCenter>
      </HomeHeader>

    </section>

  </Layout>
)

export default Success
